@font-face {
  font-family: "Ubuntu-Light";
  src: url(./fonts/Ubuntu-Light.ttf);
}
@font-face {
  font-family: "Ubuntu-Regular";
  src: url(./fonts/Ubuntu-Regular.ttf);
}
@font-face {
  font-family: "Ubuntu-Medium";
  src: url(./fonts/Ubuntu-Medium.ttf);
}
@font-face {
  font-family: "Ubuntu-Bold";
  src: url(./fonts/Ubuntu-Bold.ttf);
}

html {
  font-size: 8.2px;
}
@media (min-width: 1560px) {
  html {
    font-size: 8.8px;
  }
}
@media (min-width: 1680px) {
  html {
    font-size: 9.4px;
  }
}
@media (min-width: 1920px) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 10px;
  }
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Ubuntu-Regular;
  font-size: 1.6rem;
  color: #08183f;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3.2rem;
}
